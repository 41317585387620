import React from 'react';
import './index.css';
import { Link } from 'gatsby';
import ArberShabani from '../../images/team/arbershabani.jpeg';
// import ErezaKrasniqi from '../../images/team/erezakrasniqi.jpeg';

export const teamMembers = [
  {
    fullName: 'Arbër Shabani',
    role: 'Senior',
    position: 'React & React Native Engineer',
    photo: ArberShabani,
    url: '/cv/arber-shabani',
    hourlyRate: '40$ - 45$',
    linkedin: 'https://www.linkedin.com/in/arbershabani97/',
    email: 'arber@coetic-x.com',
  },
  // {
  //   fullName: 'Erëza Krasniqi',
  //   role: 'Junior',
  //   position: 'React & React Native Engineer',
  //   photo: ErezaKrasniqi,
  //   url: '/cv/ereza-krasniqi',
  //   hourlyRate: '25$ - 30$',
  //   linkedin: 'https://www.linkedin.com/in/er%C3%ABza-krasniqi-5b7769207/',
  //   email: 'ereza@coetic-x.com',
  // },
];

const WorksPage = () => {
  return (
    <div className="main-container">
      <div className="cards-container">
        {teamMembers.map((item, index) => {
          return (
            <div className="card" key={index}>
              <div className="card-img">
                <img src={item.photo} alt="logo" className="card-one-img" />
              </div>

              <div className="role-container">
                <p className="role">{item.role}</p>
                <p className="fullName">{item.fullName}</p>
                <p className="position">{item.position}</p>
                <p className="hourly-rate">Hourly rate: {item.hourlyRate}</p>

                <div className="buttons-container">
                  <a href={item.url}>
                    <button className="cv-button">CV</button>
                  </a>

                  <a href={item.linkedin} target="_blank" rel="noreferrer">
                    <button className="linkedin-button">
                      <svg
                        className="linkedin-icon"
                        fill="rgb(34, 33, 88)"
                        height="20px"
                        width="20px"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 310 310"
                      >
                        <g id="XMLID_801_">
                          <path
                            id="XMLID_802_"
                            d="M72.16,99.73H9.927c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5H72.16c2.762,0,5-2.238,5-5V104.73
		C77.16,101.969,74.922,99.73,72.16,99.73z"
                          />
                          <path
                            id="XMLID_803_"
                            d="M41.066,0.341C18.422,0.341,0,18.743,0,41.362C0,63.991,18.422,82.4,41.066,82.4
		c22.626,0,41.033-18.41,41.033-41.038C82.1,18.743,63.692,0.341,41.066,0.341z"
                          />
                          <path
                            id="XMLID_804_"
                            d="M230.454,94.761c-24.995,0-43.472,10.745-54.679,22.954V104.73c0-2.761-2.238-5-5-5h-59.599
		c-2.762,0-5,2.239-5,5v199.928c0,2.762,2.238,5,5,5h62.097c2.762,0,5-2.238,5-5v-98.918c0-33.333,9.054-46.319,32.29-46.319
		c25.306,0,27.317,20.818,27.317,48.034v97.204c0,2.762,2.238,5,5,5H305c2.762,0,5-2.238,5-5V194.995
		C310,145.43,300.549,94.761,230.454,94.761z"
                          />
                        </g>
                      </svg>
                    </button>
                  </a>
                </div>

                <div className="email-row">
                  <p className="reach-out">Reach out at:&nbsp;</p>
                  <p className="user-email">{item.email}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="container-4">
        <div>
          <p className="container-4-desc">
            Have a project in mind? <br />
            Let’s get to work.
          </p>
          <Link className="container-4-button" to="/hire-a-dev">
            Start a project
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WorksPage;
